<template>
  <section class="agent--partner-wrapper">
    <div class="app--links-section">
      <div class="title--text">
        {{ $t('helpDesk.agentPartner.appLinks') }}
      </div>
      <div class="d-flex justify-space-around">
        <a
          href="https://play.google.com/store/apps/details?id=com.beliruma.beliruma_agent"
          target="_blank"
        >
          <img src="@/assets/img/home/google-play-badge.svg" alt="Google Play Store" />
        </a>
        <a href="https://apps.apple.com/us/app/agen-beliruma/id1599683143" target="_blank">
          <img src="@/assets/img/home/badges-app-store.svg" alt="Apple App Store" />
        </a>
      </div>
    </div>
    <div class="title--text">
      {{ $t('helpDesk.agentPartner.title') }}
    </div>
    <div class="agent--partner-images">
      <img
        src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/mitra-agen/1.png"
      />
      <img
        src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/mitra-agen/2.png"
      />
      <div class="with--app-links">
        <img
          src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/mitra-agen/3.png"
        />
        <a
          href="https://play.google.com/store/apps/details?id=com.beliruma.beliruma_agent"
          target="_blank"
          class="play--store-link"
        >
          <img src="@/assets/img/home/google-play-badge.svg" alt="Google Play Store" />
        </a>
        <a
          href="https://apps.apple.com/us/app/agen-beliruma/id1599683143"
          target="_blank"
          class="app--store-link"
        >
          <img src="@/assets/img/home/badges-app-store.svg" alt="Apple App Store" />
        </a>
      </div>
      <img
        src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/mitra-agen/4.png"
      />
      <img
        src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/mitra-agen/5.png"
      />
      <img
        src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/mitra-agen/6.png"
      />
      <img
        src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/mitra-agen/7.png"
      />
      <img
        src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/mitra-agen/8.png"
      />
      <img
        src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/mitra-agen/9.png"
      />
    </div>
    <!--    <div class="alert alert-warning" role="alert">-->
    <!--      Mencari properti sesuai dengan kebutuhan perusahaan anda kini menjadi lebih mudah, aman, dan-->
    <!--      cepat. Rentfix hadir untuk memberikan jasa pencarian properti terbaik. Daftar untuk mencari-->
    <!--      properti yang perusahaan Anda butuhkan.-->
    <!--    </div>-->
    <!--    <agent-partner-form />-->
  </section>
</template>

<script>
// import AgentPartnerForm from '@/components/help-desk/agent-partner/agent-partner-form';

export default {
  components: {
    // AgentPartnerForm,
  },
};
</script>
